.book-image {
            border-radius: 35px 35px 0 0;
          }
          
          .book-image img {
            height: 300px;
            width: 100%;
            object-fit: cover;
          }
          
          .book-description {
            border-radius: 35px;
            background-color: white;
          }
          
          .book-description .first-row h5 {
            width: 250px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          
          .book-description .first-row p {
            color: #0084F0;
          }
          
          .book-description .second-row p:nth-child(1) {
            font-weight: 390;
          }
          
          .book-description .second-row p:nth-child(2) {
            font-weight: 390;
          }
          
          
          
          @media (max-width: 495px) {
            .book-image button{
right: 2rem !important;
            }
            .book-image img {
              height: 250px;
            }
          
            .book-description .first-row h5 {
              width: 40vw;
              font-size: 20px !important;
            }
          
            .book-description .first-row p {
              font-size: 16px !important;
            }
          
            .book-description .second-row p:nth-child(1) {
              font-size: 14px !important;
            }
          
            .book-description .second-row p:nth-child(2) {
              font-size: 14px !important;
            }
          }
          
          @media (max-width: 367px) {
            .book-image img {
              height: 220px;
            }
          
            .book-description .first-row h5 {
              width: 40vw;
              font-size: 18px !important;
            }
          
            .book-description .first-row p {
              font-size: 14px !important;
            }
          
            .book-description .second-row p:nth-child(1) {
              font-size: 12px !important;
            }
          
            .book-description .second-row p:nth-child(2) {
              font-size: 12px !important;
            }
          
            .book-description .third-row .view-book-button, 
            .book-description .third-row .add-to-cart-button {
              font-size: 14px !important;
            }
          }
          .truncate {
            white-space: nowrap; /* Prevents the text from wrapping to the next line */
            overflow: hidden;    /* Ensures the overflow is hidden */
            text-overflow: ellipsis; /* Displays "..." for overflowed text */
          }
          