.ad-card-main-container {
  border: 4px solid rgb(0, 0, 0, 0.15);
}
.ad-card-description-section p{
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ad-section{
  width: 100%;
}
.ad-card-content-container {
  column-gap: 50px;
}
.ad-card-image-container {
  height: 10rem;
  width: 16rem;
  padding: 24px;
}
.ad-card-image-container img {
  /* width: 10vw !important; */
  /* min-width: 120px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* margin: 16px 14px 10px 24px; */
}

.ad-card-text-container {
  width: 40vw;
  color: rgba(74, 74, 74, 1);
}

.ad-card-title {
  color: black;
}

.ad-card-price {
  background-color: rgba(0, 154, 240, 1);
  color: white;
}

.ad-card-chat-button {
  border: 2px solid rgb(0, 0, 0, 0.1);
  color: rgba(74, 74, 74, 1);
  transition: all 0.3s ease;
  cursor: pointer;
  font-weight: 500;
}

.ad-card-chat-button:hover {
  border: 2px solid rgba(0, 154, 240, 1);
  background-color: rgba(0, 154, 240, 1);
  color: white !important;
  box-shadow: 0px 14px 15px 0px rgba(1, 141, 243, 0.2);
}
.extra-details {
  display: flex;
  flex-wrap: wrap;
}

.extra-details-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.extra-detail-heading {
  background: rgba(238, 238, 238, 1);
  padding: 10px;
}

.extra-detail-value {
  padding: 10px;
}

.ad-card-publish-button {
  background-color: rgba(0, 154, 240, 1);
  outline: none;
  border: none;
}

.ad-card-publish-button:hover {
  background-color: rgb(18, 131, 197);
}
.ad-card-remove-button {
  background-color: rgb(253 194 194);
  outline: none;
  border: none;
  color: red;
}

.ad-card-remove-button:hover {
  background-color: rgba(0, 154, 240, 1);
}
@media (max-width: 500px) {
  .ad-section{
    width: 10rem;
  }
  .ad-card-description-section h4{
    font-size: 14px !important;
  }
  .ad-card-description-section p{
    font-size: 12px !important;
  }
  .ad-card-extra-details-section h4{
    font-size: 1rem !important;

  }
  .ad-card-text-container h3 {
    text-align: left !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    margin-bottom: 0.5rem !important;
  }
  .ad-card-text-container p{
    font-size: 12px !important;
  }
  .ad-btn button{
    padding: .2rem 0.5rem !important;
    font-size: 0.8rem !important;
  }
  .ad-card-text-container div {
    align-items: flex-start !important;
    margin: 0 !important;
  }

.colon {
  display: none;
}


  .ad-card-content-container {
    gap: 0.4rem !important;
  }
  .ad-card-image-container {
    height: 6rem;
    width: 7rem;
    padding: 0 ;
  }
  .ad-card-main-container {
    padding: 0.6rem !important;
  }
  .ad{
    font-size: 12px !important;
  }
  .extra-details{
    flex-direction: column !important;
    margin-top:  0 !important;
  }
  .ad-card-extra-details-section{
    margin: 1rem 0 !important;
  }
  .ad-card-extra-details-section h4{
    text-align: left !important;
  }
  .colon {
    display: inline;
  }
  .extra-detail-heading{
    display: flex;
    align-items: center;
    background-color: transparent !important;
    padding: 2px !important;
    font-weight: 300 !important;
  }
  .extra-detail-value{
    padding: 2px !important;
    font-weight: 300 !important;


  }
  .extra-details-item{
    flex-direction: row !important;
    text-align: left !important;
    font-size: 0.8rem !important;
    align-items: center !important;
    margin: 0 !important;
  }
.ad-card-description-section{
text-align: left !important;
margin: 1rem 0 !important;
}}

@media (min-width: 901px) and (max-width: 1120px) {
  .ad-card-image-container {
    width: 100%;
  }
  .ad-card-main-container {
    padding: 0.6rem !important;
  }
  .book-ads-grid{
    gap: 4rem !important;
  }
  .ad-card-content-container{
    flex-direction: column !important;
  }


  .ad-card-description-section h4{
    font-size: 16px !important;
  }
  .ad-card-description-section p{
    font-size: 14px !important;
  }
  .ad-card-extra-details-section h4{
    font-size: 1rem !important;

  }
  .ad-card-text-container h3 {
    text-align: left !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-bottom: 0.5rem !important;
  }
  .ad-card-text-container p{
    font-size: 14px !important;
  }
}