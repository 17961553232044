

.footer-container {
    position: relative;
    z-index: 1;
    background-color: #fff;
    bottom: 0;
    /* margin-top: 5vh; */
            padding: 4vh 5vw;
            display: grid;
            grid-template-columns: 5fr 3fr 3fr;
            grid-template-rows: auto;
            grid-template-areas:
                "section-1 section-2 section-3";
            column-gap: 50px; /* Adjust spacing between columns as needed */
            row-gap: 30px; /* Adjust spacing between rows as needed */
            border-top: 1px solid rgba(0, 0, 0, 0.10);
        }
        a{
            text-decoration: none;
            color: currentColor;
            transition: all;
        }
a:hover{
    text-decoration:underline;
    color: #0084F0;
}
        .footer-section-1 {
            grid-area: section-1;
        }
        
        .footer-section-2 {
            grid-area: section-2;
        }
        
        .footer-section-3 {
            grid-area: section-3;
        }
        .footer-container .footer-section-2 ul {
            list-style-type: disc;
        }
        .footer-container .footer-social-media-links>* {
            border-radius: 11.202px;
            background: #0084F0;
            box-shadow: 0px 0px 14.002px 0px rgba(0, 0, 0, 0.10);
            font-size: 20px;
        }
        
        .footer-container .footer-section-3 .footer-contact .contact-logo {
            background: #0084F0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            font-size: 16px !important;
        }
        
        .footer-container .text-size-larger{
            font-size: 25px !important;
          }
        .footer-container .text-size-large{
            font-size: 22px !important;
          }
          .footer-container .text-size-medium {
            font-size: 16px !important;
          }
        
        @media (max-width: 991px) {
            .footer-container {
                top: 77px;
                grid-template-columns: auto;
                grid-template-rows: auto auto;
                grid-template-areas:
                    "section-1 section-1"
                    "section-2 section-3";
                row-gap: 30px; /* Adjust spacing between rows as needed */
            }
        
            .footer-section-1 {
                grid-area: section-1;
            }
        
            .footer-section-2 {
                grid-area: section-2;
            }
        
            .footer-section-3 {
                grid-area: section-3;
            }
            .footer-container .text-size-larger{
                font-size: 18px !important;
              }
            .footer-container .text-size-large{
                font-size: 15px !important;
              }
              .footer-container .text-size-medium {
                font-size: 12px !important;
              }
        }
        
        @media (max-width: 560px) {
            .footer-container .footer-social-media-links>* {
                
                font-size: 15px;
            }
            .footer-container {
                grid-template-columns: auto;
                grid-template-rows: auto auto auto;
                grid-template-areas:
                    "section-1"
                    "section-2"
                    "section-3";
                row-gap: 30px; /* Adjust spacing between rows as needed */
            }
        
            .footer-section-1 {
                grid-area: section-1;
            }
        
            .footer-section-2 {
                grid-area: section-2;
            }
        
            .footer-section-3 {
                grid-area: section-3;
            }
            .footer-services {
                        margin: 0 !important;
            }
        }