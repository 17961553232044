.book-content {
    width: 100%;
    position: fixed;
    z-index: 9999;
    height: 100vh;
    background-color: #fff;
    top:0;
    right:0
  }
  .book-content-loader{
    width: 100%;
    height: 100%;
    position: relative;
  }
  .loader {
    /* --dim: 2rem; */
    /* width: var(--dim);
    height: var(--dim); */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Correct translation syntax */
    animation: spin988 2s linear infinite;
  }

.loader .circle {
  --color: #0084F0;
  --dim: 1.2rem;
  width: var(--dim);
  height: var(--dim);
  background-color: var(--color);
  border-radius: 50%;
  position: absolute;
}

.loader .circle:nth-child(1) {
  top: 0;
  left: 0;
}

.loader .circle:nth-child(2) {
  top: 0;
  right: 0;
}

.loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
}

.loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
}

@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
  }

  20%, 25% {
    transform: scale(1.3) rotate(90deg);
  }

  45%, 50% {
    transform: scale(1) rotate(180deg);
  }

  70%, 75% {
    transform: scale(1.3) rotate(270deg);
  }

  95%, 100% {
    transform: scale(1) rotate(360deg);
  }
}
  