.about-us-container {
   overflow: hidden;
   position: relative;
  }
  .about-section{
    margin: 0 6vw 0 6vw;

  }
  .about-content{
  padding: 10rem 0 3rem 0;
  position: relative;
  }
  .about-text {
    flex-grow: 1;
  }
  
  .about-text h2 {
    display: inline;
    font-size: 70px;
    font-weight: 700;
    letter-spacing: -3px;
  }
  .blue{
    position: absolute;
    width: 40rem;
    top: -5rem;
    right: 0;
    z-index: -10;
  }
  .red{
    position: absolute;
    width: 30rem;
    top: 20%;
    left: 0;
    z-index: -10;
  }
  .green{
    position: absolute;
    width: 30rem;
    top: 58%;
    right: 0;
    z-index: -63;
  }
  .about-text h2:nth-child(2) {
    color: #0084F0;
  }
  
  .about-text p {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.6px;
  }
  
  .header-section .highlight {
    color: #007bff; /* Highlight color */
  }
  .about-img{
    width: 95%;
    height: 100%;
  }
  .about-img img {
    height: 100%;
    width: 100%;
  }
  
  .statistics-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns by default */
    gap: 20px; /* Space between grid items */
    margin: 40px 5rem;
  }
  
 
  
  
  .stat-item {
    text-align: center;
    background-color: #fff;
    padding: 40px 20px; /* Add some padding to give space inside the box */
    border-radius: 1rem; /* Optional: for rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
  }
  
  
  .stat-item h2 {
    font-size: 40px;
    font-weight: 700;
    color: #333;
  }
    
  .stat-item p {
    font-weight: 600;
  }
  .mission-story-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 columns by default */
    gap: 20px; /* Space between grid items */
    margin: 40px 5rem;
  }
  
  .mission-story-section div {
    background-color: #fff;
    padding: 40px 20px; /* Add some padding to give space inside the box */
    border-radius: 1rem; /* Optional: for rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
  }
  
  .mission-story-section h3 {
    font-size: 25px;
    font-weight: 600;
    color:#007bff;
    margin-bottom: 20px;
  }
  /* .mission-story-section p {
    font-weight: 500;
    
  } */
  
  .why-choose-section {
    margin: 40px 5rem 6rem 5rem ;
  }
  
  .why-choose-section h3 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  

  @media (max-width: 1439px) {
    .about-text h2 {
      display: inline;
      font-size: 52px;
      font-weight: 700;
      letter-spacing: -2.4px;
    }
    
  
    .about-text p {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.4px;
      margin: 0.5rem 0;
    }
  
   
  }
  
  @media (max-width: 1107px) {
  
    .about-text p {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.3px;
    }
  
   
  }
  
  @media (max-width : 991px) {
    .about-text h2 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -2.4px;
  }
  .about-text p {
    margin: 1rem 0;
  }
    .about-content {
      padding: 0 0 2rem 0;

      height: fit-content;
      top: 77px;
      flex-direction: column;
    }
  
    .about-text {
      margin: 10vh 5vw;
    }
   
    .about-img img {
      display: none;
    }
    .blue{
        display: none;
    }
    .red{
        display: none;
    }
    .green{
        display: none;
    }
  }
  
  
  @media (max-width: 550px) {
    .about-text h2 {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -1px;
    }
  
    
  }
   /* For medium screens (e.g., tablets) */
   @media (max-width: 992px) {
    .statistics-section {
      grid-template-columns: repeat(2, 1fr); /* 3 columns */
    }
 
  }
  
  /* For small screens (e.g., mobile landscape) */
  @media (max-width: 768px) {
    .statistics-section {
      grid-template-columns: repeat(2, 1fr); /* 2 columns */
    }
    .mission-story-section {
        grid-template-columns: 1fr; /* 1 column */
        margin: 40px 1.5rem; /* Add some padding to give space inside the box */
    }
  
  }
  
  /* For very small screens (e.g., mobile portrait) */
  @media (max-width: 576px) {
    .statistics-section {
      grid-template-columns: 1fr; /* 1 column */
      margin: 40px 1.5rem; /* Add some padding to give space inside the box */

    }
    .mission-story-section h3 {
      font-weight: 400;
    }
    .why-choose-section {
        margin: 40px 1.5rem ; /* Add some padding to give space inside the box */

    }
    .why-choose-section h3 {
      font-size: 20px;
    }
  }