.tutor-service-draft-card-main-container {
  border: 4px solid rgb(0, 0, 0, 0.10);
}

.tutor-service-draft-card-container {
  column-gap: 1.5rem;
}

.tutor-service-draft-card-image img {
  width: 10vw !important;
  min-width: 130px;
  height: auto;
}

.tutor-service-draft-card-text {
  width: 100% !important;
  color: rgba(74, 74, 74, 1);
}

.tutor-service-draft-card-title {
  color: black;
}

.tutor-service-draft-card-price {
  background-color: rgba(0, 154, 240, 1);
  color: white;
}

.tutor-service-draft-card-chat-button {
  border: 2px solid rgb(0, 0, 0, 0.1);
  color: rgba(74, 74, 74, 1);
  transition: all 0.3s ease;
  cursor: pointer;
  font-weight: 500;
}

.tutor-service-draft-card-chat-button:hover {
  border: 2px solid rgba(0, 154, 240, 1);
  background-color: rgba(0, 154, 240, 1);
  color: white !important;
  box-shadow: 0px 14px 15px 0px rgba(1, 141, 243, 0.2);
}

.extra-details {
  display: flex;
  flex-wrap: wrap;
}

.extra-details-items {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.extra-detail-heading {
  background: rgba(238, 238, 238, 1);
  padding: 10px;
}

.extra-detail-value {
  padding: 10px;
}

.tutor-service-draft-card-publish-button {
  background-color: rgba(0, 154, 240, 1);
  outline: none;
  border: none;
}

.tutor-service-draft-card-publish-button:hover {
  background-color: rgb(18, 131, 197);
}
.tutor-service-draft-card-remove-button {
  background-color: rgb(253 194 194);
  outline: none;
  border: none;
  color: red;
 
}

@media (max-width: 992px) {
  .tutor-service-draft-card-text {
  text-align: left !important;
  }
  .location-row{
    align-items: flex-start !important;
  }
  .tutor-service-draft-card-description-section{
    margin: 1rem 0 !important;
  }
}
@media (max-width: 500px) {

.tutor-service-draft-card-title {
  font-weight: 500 !important;
  font-size: 20px !important;
}

.tutor-service-draft-card-description-section h4{
  font-size: 18px !important;
}
.tutor-service-draft-card-description-section p{
  font-size: 14px !important;
}

}