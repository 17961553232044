/* src/components/TutorCard.css */

.tutor-card {
            border-radius: 35px;

            box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.2);
            /* min-width: 240px;
            max-width: 280px; */
            /* width: 100%; */
            height: 30rem;
            background-color: white;
            transition: all 0.3s ease-in-out;
          }
          
          .tutor-card .tutor-info .tutor-price {
            background-color: rgba(0, 154, 240, 1);
            font-size: 13px;
            color: white;
}
          .tutor-card:hover {
            box-shadow: 0px 8px 15px rgb(0, 0, 0, 0.4);
          }
          
          .tutor-info .hire-now-button {
            background: rgb(63, 186, 255);
            background: linear-gradient(149deg, rgba(63, 186, 255, 0.9) 0%, rgba(1, 101, 251, 0.9) 100%);
            box-shadow: 0px 2px 10px 0px #2EA3FE80;
            border: none;
            outline: none;
            transition: box-shadow 0.3s ease-out;
          }
          
          .tutor-info .hire-now-button:hover {
            background: linear-gradient(149deg, rgba(63, 186, 255, 1) 0%, rgba(1, 101, 251, 1) 100%);
            box-shadow: 0px 7.7px 20.4px 0px #2EA3FE80;
          }

          @media (max-width: 991px) {
            .tutor-details-container {
              top: 77px;
            }
            .tutor-card .mobile-text-small {
              font-size: 14px !important;
            }
          }
          
.tutor-image {
  height: 14rem;
  object-fit: cover;
  width: 100%;
  border-radius: 5px;
}
.tutor-image-before {
  background: rgba(217, 217, 217, 1);
  height: 12rem;
  object-fit: cover;
  width: 11rem;

}
.tutorcard-image-before{
  background: rgba(217, 217, 217, 1);
  height: 14rem;
  object-fit: cover;
  width: 100%;
  border-radius: 5px;
}
@media (max-width: 1500px) {

  .tutor-card {
    margin: .8rem !important;
  }
}
@media (max-width: 1200px) {

  .tutor-image-before {
    width: 10rem;
}
}
@media (max-width: 991px) {

  .tutor-image-before {
    width: 45rem;
}
}
@media (max-width: 900px) {
  .tutor-image-before {
    width: 32rem;
}
}
@media (max-width: 600px) {

  .tutor-image-before {
    width: 20rem;
}
}
@media (max-width: 500px) {
  .tutor-card {
    margin: 0 !important;
  }
  .tutor-image-before {
 
    width: 15rem;
    height: 16rem;

  }
  .tutorcard-image-before{
    height: 14rem;
  }
}