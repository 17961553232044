.selling-books-library {
 
  margin: 7rem 5vw;
  min-height: 60vh;
}

.selling-books-library .selling-book-library-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* gap: 1rem; Add some space between items */
  margin-bottom: 1rem; /* Space between grid and button */
}

.selling-books-library .genre-selection-section .genre-button {
  border: 2px solid rgb(0, 0, 0, 0.1);
  color: rgba(0, 132, 240, 1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.selling-books-library .genre-selection-section .genre-button:hover {
  /* border: 2px solid rgba(0, 132, 240, 1);
  background-color: rgba(0, 132, 240, 1);
  color: white;
  box-shadow: 0px 14px 15px 0px rgba(1, 141, 243, 0.2); */
  transform: scale(1.05);
}

.selling-books-library .genre-selection-section .genre-button-active {
  border: 2px solid rgb(18, 148, 255);
  background-color: rgb(18, 148, 255);
  color: white;
  box-shadow: 0px 10px 13px 0px rgba(1, 141, 243, 0.2);
}


.selling-books-library .genre-selection-section .mySwiper {
  width: 100%; /* Ensure swiper takes full width */
  overflow: hidden; /* Hide overflow */

}
/* .swiper-wrapper{
  flex-wrap: wrap;
  justify-content: center;
} */
.genre-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto; /* Ensure each slide has proper flex behavior */
}

.genre-button {
  display: inline-block;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
}


.responsive-heading {
  font-size: 1.2rem; /* fs-5 for small screens */
  padding-left: 1rem;
}

@media (min-width: 768px) {
  .responsive-heading {
    font-size: 1.5rem; /* fs-4 for medium screens and larger */
  }
}










/* Responsive Styles */
@media (max-width: 1800px) {
  .selling-books-library .selling-book-library-cards-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1350px) {
  .selling-books-library .selling-book-library-cards-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 840px) {
  .selling-books-library .selling-book-library-cards-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 991px) {
  .selling-books-library {
    margin: 5rem 3vw;
    top: 77px;
  }
  .selling-books-library .genre-selection-section .genre-button {
    font-size: 14px !important;
  }
}
/* @media (max-width: 500px) {
  .selling-books-library .genre-selection-section .genre-button {
    font-size: 14px !important;
  }
} */
