.book-details-main-container {
margin-top: 10rem;
  padding: 3vh 7vw;
}
.book-details-main-container .book-details-container {
  column-gap: 50px;
}
.book-details-main-container .book-details-container .book-details-image {
  width: 100%; /* Full width on small screens */
}


.book-details-main-container
  .book-details-container
  .book-details-image
  .swiper-button-prev,
.book-details-main-container
  .book-details-container
  .book-details-image
  .swiper-button-next {
  color: white !important;
  /* font-size: 1px !important; */
}
.book-details-main-container
  .book-details-container
  .book-details-image
  .swiper-button-prev::after,
.book-details-main-container
  .book-details-container
  .book-details-image
  .swiper-button-next::after {
  /* color: white !important; */
  font-size: 25px !important;
  font-weight: 800;
}
.book-details-main-container .book-details-container .book-details-image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* width: 14vw !important;
  min-width: 190px !important;
  object-fit: cover; */
}
.book-details-main-container .book-details-container .book-details-text {
  width: 40vw;
  color: rgba(74, 74, 74, 1);
}
.book-details-main-container
  .book-details-container
  .book-details-text
  .book-details-title {
  color: black;
}
.fw-normal {
  font-weight: normal;
}

.text-muted {
  color: #6c757d; /* Light grey color */
}

.book-details-main-container
  .book-details-container
  .book-details-text
  .book-details-price {
  background-color: rgba(0, 154, 240, 1);
  color: white;
}
.book-details-main-container
  .book-details-container
  .book-details-text
  .book-details-chat-button {
  border: 2px solid rgb(0, 0, 0, 0.1);
  color: rgba(74, 74, 74, 1);
  transition: all 0.3s ease;
  cursor: pointer;
  font-weight: 500;
}
.book-details-main-container
  .book-details-container
  .book-details-text
  .book-details-chat-button:hover {
  border: 2px solid rgba(0, 154, 240, 1);
  background-color: rgba(0, 154, 240, 1);
  color: white !important;
  box-shadow: 0px 14px 15px 0px rgba(1, 141, 243, 0.2);
}
/* .book-details-main-container .book-extra-details-section,
            .book-details-main-container .book-description-section {
              border: 3px solid rgb(0, 0, 0, 0.1);
            } */

.extra-details {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping if needed */
}

.extra-details-items {
  flex: 1; /* Allow the item to grow and fill available space */
  display: flex;
  flex-direction: column;
  margin: 0 10px; /* Optional: spacing between items */
}

.extra-detail-heading {
  background: rgba(238, 238, 238, 1);
  padding: 10px;
  /* margin-bottom: 5px; Optional: space between heading and value */
  flex: 0; /* Take up available space */
}

.extra-detail-value {
  padding: 10px;
}
.book-details-main-container .sale-now-button {
  background-color: rgba(0, 154, 240, 1);
  outline: none;
  border: none;
}
.book-details-main-container .sale-now-button:hover {
  background-color: rgb(18, 131, 197);
}
/* .extra-detail-heading {
              background-color: rgb(0, 0, 0, 0.1);
  } */
/* .extra-details {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
  } */
  @media (min-width: 992px) {
    .book-details-main-container .book-details-container .book-details-image {
      width: 20rem; /* 50% width on large screens */
      height: 20rem;
    }
  }
@media (max-width: 991px) {
  
  .book-details-container {
    top: 77px;
  }
  .book-details-main-container .mobile-text-larger {
    font-size: 22px !important;
  }
  .book-details-main-container .mobile-text-large {
    font-size: 18px !important;
  }
  .book-details-main-container .mobile-text-medium {
    font-size: 14px !important;
  }
  .book-details-main-container .mobile-text-small {
    font-size: 12px !important;
  }
  .book-details-main-container
    .book-details-container
    .book-details-image
    .swiper-button-prev::after,
  .book-details-main-container
    .book-details-container
    .book-details-image
    .swiper-button-next::after {
    font-size: 18px !important;
  }
}


.custom-review-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.review-field{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  width: 100%;
}
.custom-button {
  width: 100%; /* Full width on small screens */
}

@media (min-width: 768px) {
  .custom-button {
    width: 150px; /* Fixed width on medium and larger screens */
  }
}

  
@media (max-width: 576px) {
  .book-details-main-container {
    margin-top: 5rem;}
  .review-field{
    flex-direction: column;
    gap: 1rem;

  }
}