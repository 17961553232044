.tutor-details-main-container {
          margin-top: 10rem;
            padding: 9vh 6vw 3.5vh;
          }
          .tutor-details-main-container .tutor-details-container {
            column-gap: 50px;
          }
          .tutor-details-image{
            width: 17rem;

          }
          .tutor-details-main-container .tutor-details-container .tutor-details-image img {
            width: 15vw !important;
            min-width: 250px;
            height: auto;
            /* margin: 28px 46px 20px 60px; */
          }
          .tutor-details-main-container .tutor-details-container .tutor-details-text .tutor-details-price {
            background-color: rgba(0, 154, 240, 1);
            color: white;
}
          .tutor-details-main-container .tutor-details-container .tutor-details-text {
            width: 40vw;
            color: rgba(74, 74, 74, 1);
          }
          .tutor-details-main-container .tutor-details-container .tutor-details-text .tutor-details-title {
            color: black;
          }
          .tutor-details-main-container .tutor-details-container .tutor-details-text .tutor-details-chat-button {
            border: 2px solid rgb(0, 0, 0, 0.1);
            color: rgba(74, 74, 74, 1);
            transition: all 0.3s ease;
            cursor: pointer;
            font-weight: 500;
          }
          .tutor-details-main-container .tutor-details-container .tutor-details-text .tutor-details-chat-button:hover {
            border: 2px solid rgba(0, 154, 240, 1);
            background-color: rgba(0, 154, 240, 1);
            color: white !important;
            box-shadow: 0px 14px 15px 0px rgba(1, 141, 243, 0.2);
          }
          /* .tutor-details-main-container .tutor-extra-details-section,
          .tutor-details-main-container .tutor-description-section {
            border: 3px solid rgb(0, 0, 0, 0.1);
          } */
          .extra-details {
            display: flex;
            flex-wrap: wrap; /* Allows wrapping if needed */
          }
          .extra-details-items {
            flex: 1; /* Allow the item to grow and fill available space */
            display: flex;
            flex-direction: column;
            margin: 0 10px; /* Optional: spacing between items */
          }
          .extra-detail-heading {
            background: rgba(238, 238, 238, 1);
            padding: 10px;
            /* margin-bottom: 5px; Optional: space between heading and value */
            flex: 0; /* Take up available space */
          }
          .extra-detail-value {
            padding: 10px;
          }
          .tutor-details-main-container .tutor-book-now-button {
            background-color: rgba(0, 154, 240, 1);
            outline: none;
            border: none;
          }
          .tutor-details-main-container .tutor-book-now-button:hover {
            background-color: rgb(18, 131, 197);
          }
          /* Responsive Styles */
          @media (max-width: 991px) {
            .tutor-details-main-container {
              margin-top: 5rem;}
            .tutor-details-container {
              top: 77px;
            }
            .tutor-details-main-container .mobile-text-larger{
              font-size: 22px !important;
            }
            .tutor-details-main-container .mobile-text-large {
              font-size: 18px !important;
            }
            .tutor-details-main-container .mobile-text-medium {
              font-size: 14px !important;
            }
            .tutor-details-main-container .mobile-text-small {
              font-size: 12px !important;
            }
          }
          