.sell-tutor-services-form {
  margin-top: 8rem;
            /* position: relative;
            top: 99px; */
            padding: 3vh 5vw;
}
.sell-tutor-services-form label,
.sell-tutor-services-form h5
  {
            color: #009AF0;
            font-weight: 600;
}

.sell-tutor-services-form .field-container input{
  outline: none;
  border: none;
}
.sell-tutor-services-form .field-container input:hover,
.sell-tutor-services-form .field-container input:focus{
  outline: none;
}
.sell-tutor-services-form .grid-container{
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 10px;
}
.condition-button {
  background: rgb(216, 216, 216);
  font-size: 12px;
  font-weight: 600;
  box-shadow: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease-out;
  border: none;
}
.selected-book-condition {
  background: linear-gradient(149deg, rgba(63, 186, 255, 0.9) 0%, rgba(1, 101, 251, 0.9) 100%);
  box-shadow: 0px 7.7px 20.4px 0px #2EA3FE80;
  color: white;
}
.sell-tutor-services-form .field-container textarea{
  outline: none;
  border: none;
}
.sell-tutor-services-form .field-container textarea:hover,
.sell-tutor-services-form .field-container textarea:focus{
  outline: none;
}

/* Dropdown */
.p-dropdown-items-wrapper {
  border-radius: 10px;
  background-color: white;
  margin: 10px 0;
  box-shadow: 3px 3px 5px rgb(0, 0, 0, 0.3);
  
}
.p-dropdown-item{
  padding: 5px 0;
}

.custom-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  background-color: #009AF0;
  color: white;
  width: 30px;
  height: 30px;
  border: none;
  padding: 3px;
  border-radius: 6px;
}
.p-dropdown-clear-icon {
  display: none;
}

/* Overview & Sale Button */
.overview-button {
  background-color: rgba(200, 200, 200, 1);
  outline: none;
  border: none;
}
.overview-button:hover {
  background-color: rgba(0, 154, 240, 1);
}
.sale-now-button {
  background-color: rgba(0, 154, 240, 1);
  outline: none;
  border: none;
}
.sale-now-button:hover {
  background-color: #0b5ed7;
}

/* .sell-tutor-services-form .field-container .add-certificates-button input {
            display: none;
          } */
.sell-tutor-services-form .field-container .add-certificates-button label {
            background: rgba(220, 220, 220, 1);
            color: rgba(88, 88, 88, 1);
            font-size: 14px;
            width: 140px !important;
          }

@media (max-width: 991px) {
            .sell-tutor-services-form {
                        top: 77px;
            }
            .sell-book-form .grid-container{
              grid-template-columns: 1fr;
            }
            .sell-tutor-services-form label,  
            .sell-tutor-services-form input,  
            .sell-tutor-services-form textarea  {
              font-size: 16px !important;
  }
  .sell-tutor-services-form .field-container .add-certificates-button label{
    font-size: 12px !important;
  }
}
/* Custom CSS for responsive font sizes */
@media (min-width: 768px) {
  .fs-md-5 {
    font-size: 1.25rem !important; /* Adjust this value as needed */
  }
}

/* Custom CSS for responsive width */
@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
}
@media (max-width: 500px) {

  .sell-tutor-services-form {
    margin-top: 5rem;
  }}