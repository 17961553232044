.add-image-after-button {
            background: linear-gradient(to bottom, #009AF0, #0051FF);
            border-radius: 50%;
          }
          .add-image-after-button {
            background: rgb(63, 186, 255);
            background: linear-gradient(149deg, rgba(63, 186, 255, 0.9) 0%, rgba(1, 101, 251, 0.9) 100%);
            box-shadow: 0px 8px 20px 0px #2EA3FE80;
            transition: all 0.3s ease-out;
}

.add-image-after-button:hover {
            background: linear-gradient(149deg, rgba(63, 186, 255, 1) 0%, rgba(1, 101, 251, 1) 100%);
            box-shadow: 0px 15px 40.4px 0px #2EA3FE80;
            transform: scale(1.1);
}
.upload-images-container, .field-container{
            border: 2px solid rgba(155, 155, 155, 1);
}
/* .upload-images-container .add-image-button #file-input {
            display: none;
          } */
          
          /* Style the custom label as a button */
.upload-images-container .add-image-button label {
            background: rgb(63, 186, 255);
            color: white;
            background-color: linear-gradient(149deg, rgba(63, 186, 255, 0.7) 0%, rgba(1, 101, 251, 0.7) 100%);
            box-shadow: 0px 2px 10px 0px #2EA3FE80;
            transition: box-shadow 0.3s ease-out;
          }
          .upload-images-container .add-image-button label:hover {
            background-color: linear-gradient(149deg, rgba(63, 186, 255, 1) 0%, rgba(1, 101, 251, 1) 100%);
            box-shadow: 0px 7.7px 20.4px 0px #2EA3FE80;
          }
          
          /* .upload-images-container images{
            background-color: rgba(155, 155, 155, 1);
          } */
@media (max-width: 576px) {
            .responsive-img {
              height: 100px !important;
              width: 39.2vw !important;
            }
          }