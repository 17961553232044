.book-type-card {
  border-radius: 35px;
  box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.2);
  width: 100%;
  /* width: 250px; */
  height: 250px;
  background-color: white;
  transition: box-shadow 0.1s ease-in-out;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: transform 0.2s ease; /* Optional: Add a smooth hover effect */
}
.book-type-card:hover {
  box-shadow: 0px 4px 10px rgb(0, 0, 0, 0.4);
}

@media (max-width: 500px) {
  .book-type-card {
    /* width: 200px; */
    height: 200px;
  }
  .category {
    padding: 0 !important;
    margin: 0 !important;

  }
}
/* .slick-initialized .slick-slide{
    width: 300px !important;
    margin: 0 1rem;
} */
.book-type-name{
  text-align: center;
}


