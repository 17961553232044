.the-navbar{
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: 100;
            box-shadow: 1px 0px 5px rgb(0, 0, 0, 0.4);
            background-color: white;
}
.navbar-toggler {
  width: 30px;
  height: 30px;
  padding: 0 !important;
}
.navbar-toggler-icon {
  width: 18px;
}
.navbar-logo {
            height: 45px;
}
.nav-links {
            font-weight: 600;
            text-decoration: none;
            color: rgb(0, 0, 0, 0.4);
            /* position: relative;
            top: 10px; */
            --bs-nav-link-hover-color:  #0084F0 !important;
}
.nav-links:hover,
.nav-links.active:hover {
            color: #0084F0 !important;
}

.nav-links.active {
  color: #0084F0 !important;
}
.active-link {
            color: #0084F0 !important;
}
.nav-button {
            font-weight: 600;
            bottom: 6px;
            color: rgb(0, 0, 0, 0.5);
            padding: 8px 16px !important;
}
.css-imxc6v-MuiBadge-badge{
    right: 6px !important;
    top: 4px !important;
}
.nav-notification {
            border: none;
            background: linear-gradient(to bottom, #009AF0, #0051FF);
            color: white;
            cursor: pointer;
            padding: 10px 18px;
            border-radius: 50%;
            transition: all 0.3s ease; /* Smooth transition for all properties */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Initial shadow for depth */
          }
          
          .nav-notification:hover {
            background: linear-gradient(to bottom, #0051FF, #009AF0); /* Reverse gradient on hover */
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Larger shadow for a lifting effect */
            transform: scale(1.04); /* Slightly scale up the button */
          }
.popper-content {
  width: 300px;
}

.popper-content .fa-chevron-right{
  transition: all 0.3s ease;
}
.popper-content div:nth-child(1):hover .fa-chevron-right,
.popper-content div:nth-child(5):hover .fa-chevron-right,
.popper-content div:nth-child(3):hover .fa-chevron-right{
  color: #0084F0 !important;
  transform: scale(1.05);
  text-shadow: 0px 1px 2px rgb(0, 0, 0, 0.4);
}
.add-button-container {
  border: 2px solid #0084F0;
}
.add-button-container p {
  color: rgba(0, 0, 0, 0.4);
}
.add-button-container .fa-plus {
  background: linear-gradient(to bottom, #009AF0, #0051FF);
  border-radius: 50%;
}
.profile-button {
  border: none;
  background: linear-gradient(to bottom, #009AF0, #0051FF);
  color: white;
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 50%;
  transition: all 0.3s ease; /* Smooth transition for all properties */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Initial shadow for depth */
}

.profile-button:hover {
  background: linear-gradient(to bottom, #0051FF, #009AF0); /* Reverse gradient on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Larger shadow for a lifting effect */
  transform: scale(1.04); /* Slightly scale up the button */
}
.nav-items-collapse{
padding-top: 1rem;
gap: 3rem !important;
}
/* .nav-items-collapse a{
font-weight: 600;
} */
.profile-options {
  width: 350px;
}
@media (max-width : 1400px) {
  .nav-items-collapse {
    gap: 1.5rem !important;
  }}
          @media (max-width : 991px) {
            .navbar {
              margin: 0 6vw !important;
            }
           
            .nav-items-collapse a{
              font-weight: 400 ;
              }
            .nav-links{
            font-size: 20px !important;
          }}
          @media (max-width : 768px) {
            .logo-text{
              font-size: 18px !important;
            } 
            .nav-links{
              font-size: 16px !important;
            }
            .nav-notification {
              padding: 7px 12px;
              font-size: 16px !important;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Initial shadow for depth */
            }
            .nav-button {
              padding: 6px 12px !important;
              font-size: 14px !important;
            }
            .nav-button i {
              font-size: 16px !important;
            }
            .popper-content h5 {
              font-size: 14px !important;
            }
            .popper-content {
              width: 250px;
            }
            .add-button-container i{
              font-size: 14px !important;
            }
            .add-button-container p{
              font-size: 15px !important;
            }
            .add-button-container {
              padding: 3px !important;
            }
            /* .profile-options {
              width: 20px !important;
            } */
          }