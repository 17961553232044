.sell-book-form {
            /* position: relative;
            top: 99px; */
            margin-top: 8rem;
            padding: 3vh 5vw;
}
.sell-book-form .field-container label,
.sell-book-form h5  {
            color: #009AF0;
            font-weight: 600;
}

.sell-book-form .field-container input{
  outline: none;
  border: none;
}
.sell-book-form .field-container input:hover,
.sell-book-form .field-container input:focus{
  outline: none;
}
.sell-book-form .grid-container{
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 10px;
}
.condition-button {
  background: rgb(216, 216, 216);
  font-size: 14px;
  font-weight: 600;
  box-shadow: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease-out;
  border: none;
}
.selected-book-condition {
  background: linear-gradient(149deg, rgba(63, 186, 255, 0.9) 0%, rgba(1, 101, 251, 0.9) 100%);
  box-shadow: 0px 7.7px 20.4px 0px #2EA3FE80;
  color: white;
}
.sell-book-form .field-container textarea{
  outline: none;
  border: none;
}
.sell-book-form .field-container textarea:hover,
.sell-book-form .field-container textarea:focus{
  outline: none;
}

/* Dropdown */
.card {
  border: none;
}
.p-dropdown-items-wrapper {
  border-radius: 10px;
  background-color: white;
  margin: 5px 0 !important;
  box-shadow: 3px 3px 5px rgb(0, 0, 0, 0.3);
  
}
.p-dropdown-item{
  padding: 4px 0 !important ;
}
.p-dropdown-items{
  padding-left: 16px !important;
  margin: 10px 0 !important;
}

.custom-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  background-color: #009AF0;
  color: white;
  width: 30px;
  height: 30px;
  border: none;
  padding: 3px;
  border-radius: 6px;
}
.p-dropdown-clear-icon {
  display: none;
}

/* Overview & Sale Button */
.overview-button {
  background-color: rgba(200, 200, 200, 1);
  outline: none;
  border: none;
}
.overview-button:hover {
  background-color: rgba(0, 154, 240, 1);
}
.sale-now-button {
  background-color: rgba(0, 154, 240, 1);
  outline: none;
  border: none;
}
.sale-now-button:hover {
  background-color: #0b5ed7;
}



@media (max-width: 991px) {
            .sell-book-form {
                        top: 77px;
            }
            .sell-book-form .grid-container{
              grid-template-columns: 1fr;
            }
            .sell-book-form .field-container label,
            .sell-book-form .field-container input,
            .sell-book-form .field-container textarea {
              font-size: 14px !important;
              font-weight: 400 !important;
  }
  .loc-btn{
    padding: 0 !important;
  }
}
@media (max-width: 500px) {

.sell-book-form {
  margin-top: 5rem;
}}
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}


.checkbox{
  width: 1.2rem;height: 1.2rem;
  margin-right: 1rem;
  background-color: #007bff;
}
.checkbox-label {
  position: relative;
  display: inline-block;
  background-color: white;
  color: #007bff;
  font-weight:500;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.2rem;
}

.checkbox-label-exchange{
  color: #333;
}