.edit-profile-button {
            background-color: rgba(235, 235, 235, 1);
            outline: none;
            border: none;
            color: black;
            overflow-x: auto;
}

.edit-profile-button:hover {
            background-color: #0184f0;
}

.profile-option-items:hover p {
            text-decoration: underline;
}
 @media (max-width: 768px) {
            
 .user-image {
            width: 60px !important;          
            height: 60px !important;          
}
.mobile-text-size {
    font-size: 14px !important;
}
.profile-options-container i {
    font-size: 14px !important;
}
 }
 .profile-image-size {
    width: 2rem;  /* Default size for small screens */
    height: 2rem; /* Default size for small screens */
}

/* Media query for larger screens */
@media (min-width: 768px) { /* Adjust the min-width as needed */
    .profile-image-size {
        width: 4rem;  /* Larger size for big screens */
        height: 4rem; /* Larger size for big screens */
    }
}
