.homepage{
  overflow-x: hidden;
}
.hero-content {
  background-image: url('../../Assets/lp-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 margin-top: 5rem;
  width: 100vw;
  height: calc (100vh-99px);
}

.hero-img img {
  top: 99px;
  height: 70vh;
  width: auto;
  max-width: fit-content;
}

.hero-text {
  margin: 0 0 0 6vw;
}

.hero-text h2 {
  display: inline;
  font-size: 70px;
  font-weight: 700;
  letter-spacing: -3px;
}

.hero-text h2:nth-child(2) {
  color: #0084F0;
}

.hero-text p {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.6px;
}

.hero-search-field {
  width: 40vw;
  height: 70px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  padding: 10px 10px 10px 20px;
  border-radius: 35px;
  border: 1px solid transparent;
  /* Initially no visible border */
  transition: border-width 0.3s ease-out, border-color 0.3s ease-out;
  box-sizing: border-box;
  background-color: white;
}

.hero-search-field input {
  width: 25vw;
  flex-grow: 2;
  border: none;
  /* Remove border from input field */
  outline: none;
  /* Remove default focus outline */
}

.hero-search-field:hover,
.hero-search-field:focus-within {
  border-width: 1px;
  /* Show border on hover or focus */
  border-color: #0084f0;
  /* Ensure border is blue */
}

.hero-search-field button {
  border: none;
  background: linear-gradient(to bottom, #009AF0, #0051FF);
  color: white;
  cursor: pointer;
  font-size: 20px;
  border-radius: 25px;
  padding: 10px 20px;
  /* Add padding for better button appearance */
  transition: all 0.3s ease;
  /* Smooth transition for all properties */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Initial shadow for depth */
}

.hero-search-field button:hover {
  background: linear-gradient(to bottom, #0051FF, #009AF0);
  /* Reverse gradient on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Larger shadow for a lifting effect */
  transform: scale(1.05);
  /* Slightly scale up the button */
}

.books-feed-header {

  margin: 10rem 6vw 0 6vw;
}
.books-feed-header .text{
  margin-bottom: 1px;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: -2px;
}
.book-feed-home{
  display: flex;
  flex-direction: column;
}
.book-feed-home p{
  color: #0084F0;
  font-size: 20px;
}
.hp-online-tutor {
  background-color: rgba(241, 241, 241, 1);
}
.hp-online-tutor .text {
  margin: 0 6vw;
}
.hp-online-tutor .text h3 {
  color: #0084F0;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -1px;
}


@media (max-width: 1439px) {
  .hero-text h2 {
    display: inline;
    font-size: 65px;
    font-weight: 700;
    letter-spacing: -2.4px;
  }
  

  .hero-text p {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.4px;
  }

  .hero-search-field {
    height: 55px;
    padding: 8px 8px 8px 20px;
  }

  .hero-search-field button {
    font-size: 16px;
    padding: 7px 15px;
    /* Add padding for better button appearance */
  }
}

@media (max-width: 1107px) {

  .hero-text p {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.3px;
  }

  .hero-search-field {
    height: 50px;
    padding: 4px 4px 4px 12px;
  }

  .hero-search-field button {
    font-size: 12px;
  }
}

@media (max-width : 991px) {
  .hero-text h2 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -2.4px;
}

  .hero-content {
    height: fit-content;
    flex-direction: column;
  }

  .hero-text {
    margin: 10vh 5vw;
    text-align: center;
  }

  .hero-img img {
    display: none;
  }

  .hero-search-field {
    width: 100%;
    height: 50px;
    padding: 4px 4px 4px 12px;
  }

  .hero-search-field button {
    font-size: 12px;
  } 
  .hero-search-field input {
    font-size: 18px !important;
    font-weight: 400 !important;
    letter-spacing: 0.1px !important;

  }

  .books-feed-header .text{
    font-size: 32px;
    letter-spacing: -1.5px;
    text-align: center;
  }
  .hp-online-tutor .text h3 {
    font-size: 32px;
    letter-spacing: -1.5px;
  }
  .hp-online-tutor .text p {
    font-size: 12px !important;
  }
}


@media (max-width: 550px) {
  .hero-text  {
    text-align: left !important;

  }


  .hero-text h2 {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: -1px;

  }
  .hero-text p {
    font-weight: 400;
    text-align: left !important;
  }
  .hero-search-field input {
    font-size: 18px !important;
  }
  .books-feed-header {
    margin: 1.5rem 6vw 0 6vw;
  }
  .books-feed-header h3{
    font-weight: 400 !important;
    font-size: 1.3rem !important;
    margin-bottom: 0 !important
  }
}