.book-carousel-card {
  background-color: transparent;
  border: none;
  margin: 5vh 6vw 5vh 6vw;
}

.book-carousel-card-container {
  /* width: max-content; */
  border-radius: 35px;
  box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.2);
  transition: box-shadow 0.1s ease-in-out;
}

.book-carousel-card-container:hover {
  box-shadow: 0px 8px 15px rgb(0, 0, 0, 0.4);
}

.book-carousel-card-container {
  border-radius: 35px;
}

@media (max-width: 495px) {
  .book-carousel-card {
    margin: 3vh 2vw;
  }

  .book-carousel-card-container {
    border-radius: 35px;
    box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.5);
    background-color: rgb(12, 112, 223, 0.7);
  }
}

@media (max-width: 367px) {
  .book-carousel-card {
    margin: 2vh 1vw;
  }

  .book-carousel-card-container {
    border-radius: 30px;
  }
}

.slick-prev:before, .slick-next:before{
  color: #0084f0 !important;
}
.slick-prev, .slick-next {
  content: "";
  display: block;
  width: 50px !important; /* adjust as needed */
  height: 50px !important; /* adjust as needed */
  background-size: contain;
  background-repeat: no-repeat;
}
.slick-prev::before, .slick-next::before {
font-size: 2rem !important;

}
.slick-prev,
.slick-next {
  z-index: 1; /* Ensure arrows are above other content */
  font-size: 10px; /* Adjust size as needed */
  font-weight: 900;
  color: #000; /* Change arrow color */
}

.slick-prev:before,
.slick-next:before {
  color: #000; /* Change arrow color */
  font-size: 24px; /* Adjust size if needed */
}

.slick-prev {
  left: 0 !important; /* Adjust left arrow position */
}

.slick-next {
  right: 0 !important; /* Adjust right arrow position */
}

/* Optional: Custom styles for better visibility */
.slick-arrow {
  background: rgba(255, 255, 255, 0.5); /* Optional: background color */
  border-radius: 50%; /* Optional: rounded arrows */
  padding: 10px; /* Optional: padding */
}
