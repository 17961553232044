.sign-up-bg {
  background-image: url("../../Assets/Signup-bg.png");
  background-size: cover;
  /* Ensures the image covers the entire element */
  background-position: center;
  /* Centers the image */
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  width: 100vw;
  height: 100vh;
}

.sign-up-container {
  background-color: white;
  box-shadow: -2px 18px 98px -4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -2px 18px 98px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 18px 98px -4px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  font-family: "Inter";
  width: 30rem;
            height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.sign-up-text .welcome-back {
  color: #0084f0;
  font-size: 35px;
  font-weight: 700;
}

.sign-up-text p:nth-child(2) {
  font-size: 13px;
}

.create-account-with {
  width: 100%;
            color: rgb(0, 0, 0, 0.50);

}

.create-account-with div {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.25);
  /* Initial border color and width */
  transition: border 0.3s ease;
  /* Smooth transition for border properties */
  transition: color 0.3s ease;
  /* Smooth transition for text color */
}

.create-account-with div:hover {
  border: 2px solid #0084f0;
  /* New border color and width on hover */
  color: #0084f0;
}

.create-account-with img {
            height: 26px
}

.create-account-button a {
  background-color: #0084f0;
}
.create-account-button {
  width: 100%;
}

/* CSS of CreateNewAccount */
.create-new-account-text p:nth-child(1) {
  color: #0084f0;
}

.create-new-account-text p:nth-child(2) {
  font-size: 13px;
}

.input-group {
  position: relative;
}

.input-container {
  position: relative;
}

.input-container i {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #1877f2;
}

.input-container input {
  padding: 15px 15px 15px 60px;
  width: 100%;
  border: 1.5px solid rgba(0, 0, 0, 0.4);
  transition: border 0.3s ease;
}

.input-container input:hover,
.input-container input:focus {
  border: 1.5px solid #0084f0;
  box-shadow: none; /* Removes the shadow effect */
}
.input-group .error-message {
  font-size: 14px;
}



/* form control*/

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: none;
}
.form-control.is-invalid:hover,
.was-validated .form-control:invalid:hover {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: none;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

.the-eye-button {
  left: 395px !important;
}
.input-container .password-input {
  padding: 15px 60px 15px 60px;
}

.submit-button {
  background-color: #0084f0;
}
.submit-button:hover {
  background-color: #0b5ed7;
}
.create-new-account-form{
  width: 100%;
}
/* Media Queries*/

@media (max-width: 500px) {
  .sign-up-text .welcome-back {
    font-size: 24px;
    font-weight: 400;
  }
  .sign-up-container {
    width: 28rem;
  }
  .create-account-with div, .empty-div {
    width: 100%;
    display: flex;
    align-items: center;
  }
 
  .input-container .form-control {
    padding: 10px 10px 10px 50px;
    width: 100%;
    height: 57px;
  }
  .input-container i {
    font-size: 20px;
  }
  .create-account-button {
    width: 100%;
  }
  .create-account-button p {
    font-size: 16px;
  }
  /* .the-eye-button {
                        left: 240px !important;
                        font-size: 16px !important;
            } */
            .the-eye-button {
              left: 266px !important;
              font-size: 14px !important;
          }
  .input-container .password-input {
    padding: 15px 50px 15px 50px;
  }
}
@media (max-width: 400px) {
  .input-container .form-control {
    font-size: 0.8rem !important;
    height: 49px;

}
.last span{
  font-size: 12px !important;
}
.sign-up-text p:nth-child(2) {
  font-size: 12px;
}
.submit-button{
  font-size: 1rem !important;
}
.the-eye-button {
  left: 226px !important;
}

   .welcome-back {
    font-size: 24px !important ;
    font-weight: 400 !important;
  }
  .sign-up-container {
    width: 19rem;
  }
  .create-account-with div{
    padding: 0.8rem !important;
  }
  .create-account-with div button{
    font-weight: 400 !important;
    font-size: 0.8rem !important;
      }
      .create-account-with div i{
        font-size: 1.8em !important;
      }   
      .create-account-with img {
        height: 22px;
    }
    .btn{
      font-weight: 400 !important;
      width: 100% !important;
      font-size: 14px !important;
      padding: .8rem 2px !important;
    }
}
@media screen and (min-width: 1600px) {
  .create-account-with div {
    margin-bottom: 1rem !important;
  }
}
