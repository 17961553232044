

.create-new-account-text p:nth-child(1) {
            color: #0084f0;
}

.create-new-account-text p:nth-child(2) {
            font-size: 13px;
}

.input-group {
            position: relative;
}

.input-container {
            position: relative;
}

.input-container i {
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            color: #1877f2;
}

.input-container input {
            padding: 15px 15px 15px 60px;
            width: 380px;
            border: 1.5px solid rgba(0, 0, 0, 0.40);
            transition: border 0.3s ease;
}

.input-container input:hover, .input-container input:focus {
            border: 1.5px solid #0084f0;
            box-shadow: none; /* Removes the shadow effect */
}