.privacy-policy-section {
    background-color: #F1FFEE;
    border-radius: 2rem;
    width: 92%;
    display: flex;
    align-items: center;
    margin: 10rem auto 0 auto;
    overflow: hidden;
    height: 26rem;
    padding: 0 1.5rem;

  }
  
  .privacy-header {
    margin-bottom: 30px;
  }
  
  .privacy-title {
    font-size: 2.5rem;
    color: #0084F0;
    
    font-weight: bold;
  }
  
  .privacy-subtitle {
    font-size: 1.1rem;
    color: #444;
    margin-top: 1.5rem;
    line-height: 2;
  }
  .privacy-policy-section img {
    width: 50%;
  }
  .privacy-content {
    width: 92%;
    margin: 4rem auto;
  }
  
  .privacy-item {
    margin-bottom: 20px;
  }
  
  .privacy-item h3 {
    font-size: 1.5rem;
    color: #0084F0;
    margin-bottom: 10px;
  }
  
  .privacy-item ul {
    padding-left: 20px;
  }
  
  .privacy-item ul li {
    list-style: disc;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #333;
  }
  @media (max-width : 991px) {
    .privacy-policy-section {
        height: auto;
        padding: 2rem;

    }
    .privacy-policy-section img {
        display: none;
    }
  }
  @media (max-width : 500px) {

  .privacy-title {
    font-size: 24px;
    text-align: center;
  }
  .privacy-subtitle{
    font-size: 1rem;

  }
  .privacy-policy-section{
    padding: 1rem;
    margin: 7rem auto 0 auto;
  }
  .privacy-content{
    margin: 2rem auto;
  }
  .privacy-item h3{
    font-size: 1rem;
  }
  .privacy-item ul li{
    font-size: .8rem;
  }
}