.demanding-books-library {

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5vw;
}

.demanding-books-library .demanding-book-library-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem; /* Add some space between items */
  margin-bottom: 1rem; /* Space between grid and button */
}
.demanding-books-library .genre-selection-section .genre-button {
  border: 2px solid rgb(0, 0, 0, 0.1);
  color: rgba(0, 132, 240, 1);
  transition: all 0.3s ease;
  cursor: pointer;
}
.demanding-books-library .genre-selection-section .genre-button:hover {
  border: 2px solid rgba(0, 132, 240, 1);
  background-color: rgba(0, 132, 240, 1);
  color: white;
  box-shadow: 0px 14px 15px 0px rgba(1, 141, 243, 0.2);
}
.demanding-books-library .genre-selection-section .genre-button-active {
  border: 2px solid rgb(18, 148, 255);
  background-color: rgb(18, 148, 255);
  color: white;
  box-shadow: 0px 10px 13px 0px rgba(1, 141, 243, 0.2);
}


/* Responsive Styles */
@media (max-width: 1830px) {
  .demanding-books-library .demanding-book-library-cards-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1350px) {
  .demanding-books-library .demanding-book-library-cards-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .demanding-books-library .demanding-book-library-cards-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 991px) {
  .demanding-books-library {
    margin: 0 3vw;
  }
  .demanding-books-library .genre-selection-section .genre-button{
    font-size: 14px !important;
  }
}

