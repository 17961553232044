/* EditProfile.css */

.edit-profile-form {
 margin-top: 10rem;
  padding: 3vh 5vw;
}

.edit-profile-form label,
.edit-profile-form h5 {
  color: #009af0;
  font-weight: 600;
  flex: 0; /* Make h6 take maximum available space */
  margin: 0;
  white-space: nowrap; /* Prevent text from wrapping */
}

.field-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-profile-form .field-container input,
.edit-profile-form .field-container textarea {
  outline: none;
  border: none;
  flex: 1; /* Make h6 take maximum available space */
}

.profile-image-container {
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-image-before {
  background: rgba(217, 217, 217, 1);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-profile-image-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  background-color: #009af0;
  color: white;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* .add-profile-image-button input {
            display: none;
          }
           */
.add-profile-image-button:hover {
  background-color: #0077cc;
}

.card {
  border: none;
}

.p-dropdown-items-wrapper {
  border-radius: 10px;
  background-color: white;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

.custom-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  background-color: #009af0;
  color: white;
  width: 30px;
  height: 30px;
  border: none;
  padding: 3px;
  border-radius: 6px;
}


.sale-now-button {
  background-color: rgba(0, 154, 240, 1);
  outline: none;
  border: none;
}

.sale-now-button:hover {
  background-color: #0b5ed7;
}

@media (max-width: 991px) {
  
  .edit-profile-form {
    margin-top: 5rem;
  }
  .edit-profile-form label,
  .edit-profile-form input,
  .edit-profile-form textarea,
  .custom-dropdown {
    font-size: 16px !important;
  }
 
}

@media (max-width: 500px) {
  .profile-image-container {
    width: 130px;
    height: 130px;
   
  }
 
  .header{
    margin:1rem 0 !important;
  }
  .header h5{
    font-size: 18px !important;
  }
  .edit-profile-form label,
  .edit-profile-form h5 {
    font-weight: 400 !important;
  }
  .field-container {
   padding: .5rem !important;
  }
  .field-container label, .field-container input{
    font-size: 14px !important;
   }
  .sale-now-button {
    width: 100% !important;
  }
}
@media (max-width: 767px) {


  .sale-now-button,.delete-account-button {
    width: 100% !important;
  }
}