.favorite-books-library {

  display: flex;
  flex-direction: column;
  margin: 10rem 5vw;
}

.favorite-books-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem; /* Add some space between items */
  margin-bottom: 1rem; /* Space between grid and button */
}

@media (max-width: 1830px) {
  .favorite-books-cards-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1350px) {
  .favorite-books-cards-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .favorite-books-cards-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 991px) {
  .favorite-books-library {
    margin: 5rem 3vw 1rem 3vw;
  }
}
