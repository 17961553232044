.gradient-button {
            border: none;
            background: linear-gradient(to bottom, #009AF0, #0051FF);
            color: white;
            cursor: pointer;
            font-size: 20px;
            border-radius: 35px;
            padding: 15px 45px;
            /* Add padding for better button appearance */
            transition: all 0.5s ease;
            /* Smooth transition for all properties */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            /* Initial shadow for depth */
          }
          
          .gradient-button:hover {
            background: linear-gradient(to bottom, #0051FF, #009AF0);
            /* Reverse gradient on hover */
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
            /* Larger shadow for a lifting effect */
            transform: scale(1.05);
            /* Slightly scale up the button */
          }
          @media (max-width: 768px) {
            .gradient-button {
            font-size: 18px;
            padding: 10px 28px;
            /* Add padding for better button appearance */
            transition: all 0.3s ease;
            /* Smooth transition for all properties */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            /* Initial shadow for depth */
          }
            }
          @media (max-width: 520px) {
            .gradient-button {
            font-size: 16px;
            padding: 8px 24px;
            /* Add padding for better button appearance */
            transition: all 0.3s ease;
            /* Smooth transition for all properties */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            /* Initial shadow for depth */
          }
            }
          