.book-description .third-row .view-book-button{
            border: 1px solid #0084f0;
            outline: none;
}
.book-description .third-row .view-book-button:hover {
            background-color: #0084f0;
          }
          
          .book-description .third-row .add-to-cart-button {
            background-color: #0084f0;
            outline: none;
            border: none;
          }
          
          .book-description .third-row .add-to-cart-button:hover {
            background-color: #0b5ed7;
          }
          @media (max-width: 367px) {
            
            .book-description .third-row .view-book-button, 
            .book-description .third-row .add-to-cart-button {
              font-size: 14px !important;
            }
          }
          