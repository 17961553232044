.tutor-ads-container {
           
            margin: 10rem auto;
            padding: 2vh 0;
            width: 95%;
          }
          
          .tutor-ads-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            margin-bottom: 1rem;
            width: 100%;
          }
          
          @media (max-width: 1024px) {
            .tutor-ads-grid {
              grid-template-columns: 1fr;
            }
          }
          
          @media (max-width: 991px) {
            .tutor-ads-container {
              margin: 5rem auto;
            }
          
          
          .load-more-wrapper {
            margin-top: 1rem;
            text-align: center;
          }
        }