.tutor-library {
         
            margin: 7rem 5vw 0 5vw;
            padding: 5vh 0;;
          }
          
          .tutor-library .tutor-library-cards-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr ;
            gap: 1.5rem;
            margin-bottom: 1rem; /* Space between grid and button */
            /* row-gap: 2vh; */
          }
          
          /* Genre buttons styling */
          .tutor-library .genre-selection-section .genre-button {
            border: 2px solid rgb(0, 0, 0, 0.1);
            color: rgba(0, 132, 240, 1);
            transition: all 0.3s ease;
            cursor: pointer;
          }
          
          /* Responsive Styles */
          @media (max-width: 1500px) {
            .tutor-library .tutor-library-cards-container {
              grid-template-columns: 1fr 1fr 1fr 1fr;
            }
          }
          
          @media (max-width: 1400px) {
            .tutor-library .tutor-library-cards-container {
              grid-template-columns: 1fr 1fr 1fr;
            }
          }
          
          @media (max-width: 1000px) {
            .tutor-library .tutor-library-cards-container {
              grid-template-columns: 1fr 1fr;
            }
          }
          @media (max-width: 800px) {
            .tutor-library .tutor-library-cards-container {
              grid-template-columns: 1fr;
            }
          }
          
          @media (max-width: 991px) {
            .tutor-library {
              margin: 5rem 3vw;
            }
          }
          
          .load-more-container {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 1rem;
          }
          