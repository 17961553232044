.book-ads-container {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10rem 4vw;
  padding: 3vh 0;
}

.book-ads-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 1rem;
}

@media (max-width: 900px) {
  .book-ads-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 991px) {
  .book-ads-container {
    margin: 5rem 3vw;
  }
}

.load-more-wrapper {
  margin-top: 1rem;
  text-align: center;
}

.screen-size{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: gray;
  font-weight: 500;

}
@media (max-width: 500px) {
  .screen-size{
    font-size: 1rem !important;  
    font-weight: 400;

  }
}