
.custom-backdrop {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1050;
          }
          
          .custom-modal {
            background-color: #fff;
            border-radius: 10px;
            padding: 20px;
            width: 400px; /* Default width for larger screens */
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
            position: relative;
          }
          
        .modal-cross{
          font-size: 30px;
          display: flex;
          justify-content: end;
          cursor: pointer;
        }
        .scroll {
          overflow-y: auto; /* Enable vertical scrolling */
          max-height: 300px; /* Set a specific height for demonstration */
        }
        
        .scroll::-webkit-scrollbar {
          width: 8px; /* Width of the scrollbar */
        }
        
        .scroll::-webkit-scrollbar-track {
          background: #f1f1f1; /* Color of the scroll track */
          border-radius: 10px; /* Rounded corners for the track */
        }
        
        .scroll::-webkit-scrollbar-thumb {
          background: #888; /* Color of the scroll thumb */
          border-radius: 10px; /* Rounded corners for the thumb */
        }
        
        .scroll::-webkit-scrollbar-thumb:hover {
          background: #555; /* Darker color when hovering over the thumb */
        }
        
          
          .modal-title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 15px;
          }
          
          .modal-description {
            font-size: 16px;
            margin-bottom: 20px;
          }
          @media (max-width: 768px) {
            .custom-modal {
              width: 300px; /* Smaller width for medium/small screens (like tablets) */
            }
          }
          
          @media (max-width: 576px) {
            .custom-modal {
              width: 90%; /* Even smaller width for extra small screens (like phones) */
            }
          }